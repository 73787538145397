import { FaPhone, FaEnvelope } from 'react-icons/fa';

import './Footer.css'

function Footer() {
    return (
      <div className="footer">
        <div id="contact">Contact Us</div>
        <div id="number"><FaPhone className="icon" /> <a href="tel:+1-647-276-1616">647-276-1616 </a></div>
        <div id="email"><FaEnvelope className="icon" /> <a href="mailto:info@assetsoft.biz">info@assetsoft.biz </a></div>
      </div>
    );
  }
  
  export default Footer;