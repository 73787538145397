import React, { useEffect, useState } from 'react';
import queryString from 'query-string';

import { concurInvoiceConnect } from '../../api/invoiceConnect';

import './Connect.css'

function Connect() {

    const [showForm, setShowForm] = useState(true);
    const [verificationCode, setVerificationCode] = useState("");
    const [agrreeTerms, setAgreeTerms] = useState(false);
    const [agrreePrivacy, setAgreePrivacy] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const Connect = async() => {
        setErrorMessage("");
        setSuccessMessage("");
        
        const concurValues = queryString.parse(window.location.search);
        console.log(concurValues);

        if(verificationCode == "" || agrreeTerms == false || agrreePrivacy == false) {
            setErrorMessage("Please enter a verification code, agree the Terms and Conditions and Privacy Policy to connect!");
        } else {
            const data = await concurInvoiceConnect(concurValues.id, concurValues.requestToken, concurValues.userId, verificationCode);
            
            if(data.success) {
                console.log(data.message);
                setSuccessMessage("Congratulations on Successful Connection! Assetsoft App login details has been sent on your registered email id. You can close this window to complete connection process.");
                setShowForm(false);
            } else {
                setErrorMessage(data.message);
            }
        }
        
    }

    function CloseWindow() {
        window.open('','_self').close();
    }

    function changeVerificationCode(event) {
        setVerificationCode(event.target.value);
    }

    function changeAgreeTerms(event) {
        setAgreeTerms(event.target.checked);
    }

    function changeAgreePrivacy(event) {
        setAgreePrivacy(event.target.checked);
    }

    return (
        <div className="container">

            <div className="heading">Setup Assetsoft Connection with Concur Invoice</div>
            
            {errorMessage && (
                <div className="error"> { errorMessage } </div>
            )}

            {successMessage && (
                <div className="success"> { successMessage } </div>
            )}

            {showForm && (
                <>
                    <p>A code will be sent to the email you registered with us in order to secure your Assetsoft connection with Concur Invoice.</p>
                    <p>Enter the code sent to your email:</p>
                    
                    <form>
                        <div className="form-group">
                            <input type="text" className="form-field" placeholder="Verification Code" name="verificationCode" id='verificationCode' required value={verificationCode} onChange={changeVerificationCode}/>
                        </div>
                        <p>Need some assistance?  – <a href="https://www.assetsoft.biz/contact-us" target="_blank" rel="noreferrer">Click Here</a></p>
                        <label className="checkbox"><input type="checkbox" name="terms" value="terms" checked={agrreeTerms} onChange={changeAgreeTerms} required /> &nbsp; I agree to the <a href="https://www.assetsoft.biz/terms-and-conditions" target="_blank" rel="noreferrer"> Terms and Conditions. </a></label>
                        <label className="checkbox"><input type="checkbox" name="policy" value="policy" checked={agrreePrivacy} onChange={changeAgreePrivacy} required /> &nbsp; I agree to the <a href="https://www.assetsoft.biz/privacy-policy" target="_blank" rel="noreferrer"> Privacy Policy. </a></label>
                        <input type="button" value="Continue" className="continue" onClick={Connect}/>
                        <input type="button" value="Cancel" className="cancel" onClick={CloseWindow}/>
                    </form>
                </>
            )}
            
        </div>
    );
  }

  export default Connect;