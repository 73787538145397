import './Header.css'
import assetsoft_logo from '../../images/assetsoft_logo.png'
import concur_logo from '../../images/concur_logo.png'

function Header() {
    return (
        <div>
            <div className="header">
                <img src={assetsoft_logo} className="assetsoft-logo" alt="assetsoft-logo" />
                <img src={concur_logo} className="concur-logo" alt="concur-logo" />
            </div>
        </div>
    );
  }
  
  export default Header;